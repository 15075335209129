import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const fetchTechIds = createAsyncThunk("practice/techIds", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api9/fetchtechid", reqBody);
    const respData = await res.json();

    return respData;
});
export const fetchPracticeDetails = createAsyncThunk("practice", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body)
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api10/fetch-pratice-partial-data", reqBody);
    const respData = await res.json();
    return respData;
})
export const fetchDiabaticPracticeDetails = createAsyncThunk("practice", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body)
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api5/getpracticeinfo", reqBody); //findpracticeinfo
    const respData = await res.json();
    return respData;
})

const practiceSlice = createSlice({
    name: "practice",
    initialState: {
        loading: false,
        success: false,
        error: false,
        techIds: [],
        practiceId: "",
        practiceDetails: {},
        theme: "",
        lightBoxModal: false,
        uniqueCodeLocationDetails: null,
        fetchDiabaticPracticeDetailsError: false
    },
    reducers: {
        setInitialData(state, action) {
            state.practiceId = action.payload.practiceIds
            state.theme = action.payload.theme
            console.log(action)
                // if (action.payload.techIds !== 'undefined') {
                //   state.techIds = action.payload.techIds
                // }
        },
        setLightBoxActive(state, action) {
            state.lightBoxModal = action.payload
        },
        setUniqueCodeLocationDetails(state, action) {
            console.log("uniqueCodeLocationDetails ", action.payload)
            state.uniqueCodeLocationDetails = action.payload
        }

    },
    extraReducers: {
        /////////////////////////////////// Fetch Table List ///////////////////////////
        [fetchTechIds.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchTechIds.fulfilled]: (state, action) => {
            console.log(action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.success = true;
                state.techIds = action.payload.res
            }
        },
        [fetchTechIds.rejected]: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        },
        [fetchPracticeDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchPracticeDetails.fulfilled]: (state, action) => {
            console.log("storeData action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.success = true;
                state.practiceDetails = action.payload.res[0]
            }
        },
        [fetchDiabaticPracticeDetails.rejected]: (state, action) => {
            console.log("fetchDiabaticPracticeDetails storeData rejected action", action);
            if (action.payload.status === "error") {
                state.loading = false;
                state.success = false;
                state.error = true;
                state.fetchDiabaticPracticeDetailsError = true;
            }
        },
        [fetchDiabaticPracticeDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchDiabaticPracticeDetails.fulfilled]: (state, action) => {
            console.log("fetchDiabaticPracticeDetails storeData fulfilled action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.success = true;
                if(action.payload.length>0)
                    state.practiceDetails = action.payload.result[0]
                state.fetchDiabaticPracticeDetailsError = false;
            }
        },


    }
});

export default practiceSlice.reducer;

export const { setInitialData, setLightBoxActive, setUniqueCodeLocationDetails } = practiceSlice.actions;